import React from 'react';
import { PageProps } from 'gatsby';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';

import Layout, { LayoutOptions, HeroSectionColors } from '../../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../../localization/translations';
import PageMenu from '../../../../components/PageMenu/PageMenu';
import { IconTypes } from '../../../../components/Icon/Icon';
import { getPathFromRouteKey } from '../../../../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../../../../config/RouteKeys';
import InfoBlock from '../../../../components/InfoBlock/InfoBlock';
import { Accordion, AccordionItem } from '../../../../components/Accordion/Accordion';

type Props = PageProps & WithTranslation;

const Improvements: React.FC<Props> = (props) => {
  const { t } = props;

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t(`improvements.seo.title`),
    },
    page: {
      title: t(`improvements.heading`),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        heroImage: {
          fileName: 'hero-improvements.png',
          alt: '',
        },
        blockText: t('improvements.heroHeading'),
        shouldBeDigiBlock: true,
      },
    },
  };

  return (
    <Layout options={layoutOptions} className="ResidentialMortgages">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <div className="ResidentialMortgages__generic-gap">
              <p>
                <Trans i18nKey="improvements.body" t={t} />
              </p>
            </div>
            <div className="mb-1">
              <h2>
                <Trans i18nKey="improvements.list.heading" t={t} />
              </h2>
              <div className="row row-wrap">
                {(t('improvements.list.items', { returnObjects: true }) as {
                  title: string;
                  description: string;
                  icon: IconTypes;
                }[]).map(({ title, description, icon }) => (
                  <div className="column column-40 mb-2" key={title}>
                    <InfoBlock heading={<h3 className="h5">{title}</h3>} icon={icon}>
                      <Trans>{description}</Trans>
                    </InfoBlock>
                  </div>
                ))}
              </div>
            </div>
            <div className="ResidentialMortgages__generic-gap">
              <Accordion allowZeroExpanded>
                <AccordionItem buttonOptions={{ label: t('improvements.seeDetails.heading') }}>
                  <ul>
                    {(t('improvements.seeDetails.list', { returnObjects: true }) as string[]).map(
                      (item, index) => (
                        <li key={`${index}-${item.length}`}>{item}</li>
                      )
                    )}
                  </ul>
                  <p>{t('improvements.seeDetails.body')}</p>
                  <p>
                    <Trans i18nKey="improvements.bodyTwo" t={t} />
                  </p>
                </AccordionItem>
              </Accordion>
            </div>
          </section>
          <aside className="column column-33">
            <PageMenu
              menu={[
                {
                  to: getPathFromRouteKey(RouteKeys.BROKER_CENTRE.MEET_THE_TEAM),
                  textKey: 'pageTitles.meetTheTeam',
                },
                {
                  to: getPathFromRouteKey(RouteKeys.BROKER_CENTRE.BROKER_PORTAL),
                  textKey: 'pageTitles.brokerPortal',
                },
              ]}
            />
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.CUSTOMER_CENTRE)(Improvements);
